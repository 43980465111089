<template>
  <div class="content">
    <sub-title>쪽지</sub-title>
    <div class="message">
      <div class="message_btns">
        <button class="btn-ok" @click="checkedAll()"><i class="fa fa-check"></i>쪽지내역 전체확인</button>
        <button class="btn-del" @click="deleteAll()"><i class="fa fa-close"></i>쪽지내역 전체삭제</button>
      </div>
      <div class="message_cont" v-for="(item, index) in messageList" :key="index">
        <div class="m_new">
          <div class="time">

            <div class="t2">{{item.createTime|datef('MM월DD일 HH시mm분')}}</div>
            <div class="t1"  v-if="item.checked ===  sportsConst.UNCHECKED">미확인</div>
            <div class="t3"  v-if="item.checked ===  sportsConst.UNCHECKED">
              <button class="btn-ok" @click="checkedMessageById(item.id)">확인하기</button>
            </div>
            <div class="t3"  v-if="item.checked !==  sportsConst.UNCHECKED">
              <button class="btn-del"  @click="deleteMessageById(item.id)">삭제</button>
            </div>
          </div>
          <div class="cont" v-if="item.type === sportsConst.MESSAGE_TYPE_PRIVATE" v-html="item.content" :class="{'bg-light':item.checked ===  sportsConst.UNCHECKED}">
          </div>
          <div class="cont" v-if="item.type === sportsConst.MESSAGE_TYPE_PUBLIC"
               v-html="item.publicMessage.content"  :class="{'bg-light':item.checked ===  sportsConst.UNCHECKED}">
          </div>
        </div>
      </div>
    </div>

      <!--페이지-->
      <pagination :page-index="pageNum"
                  :total="total"
                  :page-size="pageSize"
                  @change="pageChange"
                  v-if="messageList.length > 0"></pagination>

    </div>

</template>


<script>

import {
  checkedMessageAll,
  checkedMessageById,
  deleteMessageAll,
  deleteMessageById,
  delQuestion,
  getMessageList,
  getQuestionList,
  saveQuestion
} from "@/network/userRequest";
    import Pagination from "@/components/pagenation/Pagination";
     import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
import {checkLoginMinxin, checkLoginOnCreateMinxin, postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";

    export default {
      name: "Message",
      mixins: [postionMixin,checkLoginOnCreateMinxin],
      components: { Pagination, SubTitle, },
      data() {
        return {
          messageList: [],
          pageNum: 1,
          pageSize: 5,
          total: 1,
          sportsConst,
          position: "쪽지",
        }
      },
      methods: {
        checkedMessageById(id) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          checkedMessageById(id).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.initMessageList();
              this.$store.dispatch('actionUnCheckedMessageCount')
            }
          })
        },
        deleteMessageById(id) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          deleteMessageById(id).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.initMessageList();
              this.$store.dispatch('actionUnCheckedMessageCount')
            }
          })
        },
        checkedAll() {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          checkedMessageAll().then(res => {
            if (res.data.success) {
              this.$store.commit(RECEIVE_HIDE_LOADING)
              this.initMessageList();
              this.$store.dispatch('actionUnCheckedMessageCount')
            }
          })
        },
        deleteAll() {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          deleteMessageAll().then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.initMessageList();
              this.$store.dispatch('actionUnCheckedMessageCount')
            }
          })
        },
        initMessageList() {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          getMessageList(this.pageNum, this.pageSize).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.total = res.data.total
              this.messageList = res.data.data
            }
          })
        },
        pageChange(page) {
          this.pageNum = page
          this.initMessageList()
        },
      },
      created() {
        this.initMessageList()
      }
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontmessage.css");




</style>