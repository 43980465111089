<template>
  <div class="subtitle">
    <div class="name">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "SubTitle"
}
</script>
<style scoped>

</style>